@import './assets/fonts/SofiaPro/sofia-pro-regular-webfont.ttf';
@font-face {
  font-family: 'SofiaPro';
  src: local('sofia-pro-regular-webfont'),
    url('./assets/fonts/SofiaPro/sofia-pro-regular-webfont.ttf') format('truetype');
}
@import './assets/fonts/Inter/Inter.ttf';
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./assets/fonts/Inter/Inter.ttf') format('truetype');
}
@import '@primer/css/utilities/index.scss';
@import '@primer/css/layout/index.scss';
@import 'react-toastify/dist/ReactToastify.css';

@import 'assets/scss/colors.scss';
@import 'assets/scss/typography.scss';
@import 'assets/scss/forms.scss';
@import 'assets/scss/buttons.scss';

@import 'assets/scss/authloader.scss';
@import 'assets/scss/searchform.scss';
@import 'assets/scss/tabs.scss';
@import 'assets/scss/prognostogram.scss';
@import 'assets/scss/orderprognostogram.scss';
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import 'assets/scss/profile.scss';
@import 'assets/scss/onboarding.scss';
@import 'assets/scss/rangeslider.scss';
@import 'assets/scss/notification.scss';
@import 'assets/scss/images.scss';
@import 'assets/scss/spacing.scss';
@import 'assets/scss/template.scss';

:root {
  //text
  --color-Neutral-400: #b7b7b7;
  --color-neutral-900: #1a1a1a;
  --color-primary: #016f66;
  --color-black-opacity-87: rgba(0, 0, 0, 0.87);
  --color-black-opacity-60: rgba(0, 0, 0, 0.6);
  --color-white: #ffffff;
  --color-gray-80: #343541;
  --color-dark: #000;
  --color-Neutral-700: #6c6c6c;
  --color-error: #e24c4c;
  --color-black: #000;
  --color-muted-Gray: #797a7c;
  --color-gray-dark: #262626;
  --color-danger-100: #b91c1c;
  --color-primary-1: #0d3f3c;
  --color-warning: #ca8a04;
  --color-neutral-800: #1a1a1aba;
  --color-danger: #dc2626;
  --color-slate-gray: #454545;
  --color-info: #3b82f6;

  //bg
  --bg-primary: #016f66;
  --bg-gray-50: #f9fafb;
  --bg-gray-300: #d1d5db;
  --bg-white: #ffffff;
  --background-dark-teal: #0d3f3c;
  --background-primary-light: #d8eeee;
  --bg-dark-teal: #0d3f3c;
  --bg-black-opacity-60: rgba(0, 0, 0, 0.6);
  --bg-inactive-gray: rgb(193, 193, 193);
  --bg-gray-200: #f7f7f7;
  --bg-danger-light-200: #fef2f2;
  --bg-danger-light-100: #fee2e2;
  --bg-danger: #b91c1c;
  --bg-gray-100: #eee;
  --bg-warning-primary: #fefce8;
  --bg-light-mint: #f0fdf4;
  --bg-danger-light: #fef2f2;
  --bg-neutral-200: #dcdcdc;
  --bg-info: rgba(219, 234, 254, 0.7);

  //borders
  --border-gray-300: #d1d5db;
  --border-secondary-300: #a5c7ed;
  --border-on-surface-medium-emphasis: rgba(0, 0, 0, 0.6);
  --border-primary-light: #d8eeee;
  --border-gray-light: #c2c2c2;
  --border-gray-neutral: #bebec8;
  --border-dark-teal: #0d3f3c;
  --border-soft-green: #d4eae5;
  --border-ocean-green: rgb(1, 111, 102);
  --border--muted-green-1: #658583;
  --border-gray-400: #6b6b6b;
  --border-neutral-200: #dcdcdc;
  --border-warning: #fcde64;
  --border-danger-light: #f87171;
  --border-teal-dark: #026f66;
  --border-info: #3b82f6;

  //border-radius
  --border-radius-md: 6px;
  --border-radius-lg: 10px;
  --border-radius-7: 7px;
  --border-radius-8: 8px;
  --border-error: #e24c4c;
  --border-radius-10: 10px;
  --border-radius-2: 2px;

  // gradients
  --primary-bg-gradient: linear-gradient(86deg, #0d3f3c 0%, #1e6763 100%);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}

body {
  font-family: 'SofiaPro', sans-serif;
  min-height: 100vh;
}

::selection {
  background: $primary-color;
  color: $white;
}

.ReactModal__Body--open {
  overflow: hidden;
  // #root {
  //   overflow: hidden;
  // }
}

.font-bold {
  font-weight: 700 !important;
}

div {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-family: 'SofiaPro', sans-serif;
  color: $text-primary-color;
}

.app-container {
  min-height: 100vh;
  > div {
    min-height: 100vh;
    max-width: 100%;
  }
}

.topnav {
  align-items: center;
  padding: 12px 5%;
  @include breakpoint(md) {
    padding: 9px 40px;
  }
  .signin-btn {
    padding: 10px 20px;
    @include breakpoint(md) {
      padding: 10px 30px;
    }
  }
}

.text-center {
  text-align: center;
}

.avatar {
  border: none;
  color: $white;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  img {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    line-height: initial;
  }
  .nav-img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: $gradient-background;
    line-height: initial;
  }
  .no-image {
    width: 96px;
    height: 96px;
    font-size: 36px;
    border-radius: 50%;
    background: $gradient-background;
  }
}

/* Profile dropdown */
details {
  position: relative;
  summary {
    border: none !important;
    padding: 0 !important;
  }
}
details[open] .avatar {
  opacity: 0.5;
}

details[open] summary::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 80;
  display: block;
  cursor: default;
  content: ' ';
  background: transparent;
}

details > summary::before {
  display: none;
}

details > summary + div > div {
  width: 145px !important;
  font-size: 14px !important;
  border: none !important;
  border-radius: 6px !important;
  box-shadow: 2px 2px 17px rgba(140, 149, 159, 0.5);
  padding: 5px 0;
  margin-top: 4px !important;
  background-color: $white;
}

details > summary + div > div a {
  border-bottom: 0 !important;
  padding: 5px 0 !important;
  font-size: 14px !important;
  line-height: 14px;
}

details > summary + div {
  right: 0px !important;
  z-index: 100 !important;
  bottom: auto !important;
  left: auto !important;
  top: auto !important;
  padding: 0 !important;
  position: absolute !important;
}

.page-title {
  font-family: 'SofiaPro', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: $black;
  letter-spacing: 0.25px;
  @include breakpoint(md) {
    font-size: 34px;
    font-weight: 400;
  }
}

.w-48 {
  width: 100%;
  @include breakpoint(md) {
    width: 48%;
  }
}

.white-overlay {
  background: transparent !important;
  opacity: 1 !important;
}

.errors-container {
  width: 90%;
  margin: 0 auto;
  color: $black;
  @include breakpoint(md) {
    width: 65%;
    margin: 0 auto;
  }
  a {
    color: $black;
  }
}

.home-container {
  width: 90%;
  margin: 0 auto;
  @include breakpoint(md) {
    width: 60%;
  }
}

.logoContainer {
  margin-top: 0;
  img {
    width: 170px;
    height: auto;
    max-width: 100%;
    @include breakpoint(sm) {
      width: 245px;
    }
  }
}

//modal
.modal-overlay {
  background: rgba(20, 20, 20, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;
}
.modal {
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  margin-right: 0;
  width: 100%;
  background: $black;
  border-radius: 4px;
  position: absolute;
  overflow: auto;
  outline: none;
  opacity: 0.92;
  height: 100vh;
  text-align: center;
  padding: 20px;
  .modal-container {
    color: $white;
    font-size: 32px;
    padding-top: 8%;
  }
  .modal-desc {
    text-align: left;
    @include breakpoint(md) {
      text-align: center;
    }
  }
  .gradient-btn {
    width: 90%;
    position: absolute;
    left: 5%;
    bottom: 20%;
    @include breakpoint(md) {
      width: auto;
      position: relative;
      left: 0;
    }
  }
}

.error-container {
  width: 100%;
  color: $error-color;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: $error-color;
  }
  @include breakpoint(md) {
    width: 75%;
  }
}

.email-outer-container {
  width: 100%;
  margin: 0 auto;
  box-shadow: none;
  height: auto;
  min-height: auto !important;
  justify-content: center;
  display: flex;
  max-width: 100%;
  @include breakpoint(md) {
    width: 400px;
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
    height: 540px;
  }
  .email-inner-container {
    padding: 10%;
    justify-content: center;
    text-align: center;
    font-size: 14px;
  }

  .email-error-icon {
    width: 60px;
    height: 60px;
    border: 2px solid $error-color;
    border-radius: 50%;
    font-size: 24px;
    color: $error-color;
  }

  .email-success-icon {
    width: 60px;
    height: 60px;
    border: 2px solid $success-color;
    border-radius: 50%;
    font-size: 24px;
    color: $success-color;
  }

  .email-header-text {
    font-size: 25px;
  }
}

.unverified-outer-container {
  width: 100%;
  margin: 0 auto;
  height: auto;
  min-height: auto !important;
  justify-content: center;
  display: flex;
  max-width: 100%;
  @include breakpoint(md) {
    width: 400px;
    height: 540px;
  }
  .email-inner-container {
    justify-content: center;
    text-align: center;
    font-size: 14px;
    width: 100%;
  }
  .email-error-icon {
    width: 60px;
    height: 60px;
    border: 2px solid $primary-color;
    border-radius: 50%;
    font-size: 24px;
    color: $primary-color;
  }
}

.infinite-scroll-component {
  overflow: hidden !important;
}
.seeMore {
  line-height: 24px;
  padding: 0;
  background-color: transparent;
  border: transparent;
  color: $black;
  font-weight: 600;
  font-size: 19px;
  font-family: 'SofiaPro', sans-serif;
  -webkit-transform: rotate(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
  cursor: pointer;
  text-decoration: underline;
}
.titleDiv {
  position: relative !important;
}

.ReactModalPortal + iframe {
  width: 0% !important;
  height: 0 !important;
}
.modal-video-movie-wrap iframe {
  width: 100% !important;
  height: 100% !important;
}
.portal-logo {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.tagContainer {
  height: 20px;
  min-width: 20px;
  white-space: nowrap;
  border-radius: 4px;
}
.row-flex {
  &.flex-shrink-0 {
    flex-shrink: 0;
  }
}

.ah-dropdown-panel {
  background: var(--bg-white);
  border-radius: var(--border-radius-8);
  border: 1px solid var(--border-gray-light);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .p-dropdown-items {
    padding: 10px 8px;

    .p-dropdown-item {
      margin: 0;
      padding: 10px;
      border: 0 none;
      color: var(--color-black-opacity-87);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;

      &.p-highlight {
        background: var(--bg-primary);
        border-radius: var(--border-radius-md);
        color: var(--color-white);
      }
    }

    .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
      color: #4b5563;
      background: var(--bg-gray-50);
      border-radius: var(--border-radius-8);
    }
  }

  &.has-svg {
    .p-dropdown-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  &.p-multiselect-panel {
    .p-multiselect-items {
      padding: 10px 8px;

      .p-multiselect-item {
        .p-checkbox-input {
          display: none;
        }
      }

      .p-multiselect-item-group,
      .p-multiselect-item {
        margin: 0;
        padding: 10px;
        border: 0 none;
        color: var(--color-black-opacity-87);
        background: transparent;
        transition: box-shadow 0.2s;
        border-radius: 0;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
      .p-multiselect-item-group {
        color: var(--color-muted-Gray);
        text-transform: uppercase;
        font-size: 16px;
      }

      &:not(.p-highlight):not(.p-disabled).p-focus {
        color: #4b5563;
        background: #f3f4f6;
      }
    }
    .p-multiselect-empty-message {
      color: var(--color-neutral-900);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
    .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
      background: var(--bg-gray-50);
    }
  }
}

.list {
  &.list-disc {
    list-style-type: disc;
  }

  &.dashed-list {
    list-style-type: none;
    li::before {
      content: '- ';
      margin-right: 5px;
    }
  }
}

.ah-groupMultiselect-dropdown-panel,
.ah-multiselect-dropdown-panel {
  .p-multiselect-header {
    display: none;
  }
}

.p-checkbox {
  margin-right: 7px;

  .p-checkbox-box {
    border: 1px solid #202224;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #4b5563;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    outline-color: transparent;
  }

  &.p-highlight {
    .p-checkbox-box {
      border-color: var(--border-dark-teal);
      background: var(--bg-dark-teal);
    }
  }

  .p-checkbox-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .p-checkbox-icon {
      width: 14px;
      height: 14px;
      color: var(--color-white);
      font-weight: 700;
    }
  }
  .p-checkbox-input {
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: 1;
    outline: 0 none;
  }
}

.p-tooltip .p-tooltip-text {
  background: rgba(97, 97, 97, 0.9);
  border-radius: 4px;
  font-size: 12px;
  color: var(--color-white);
  padding: 5px;
  border-radius: 4px;
  max-width: 250px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #495057;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #495057;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #495057;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #495057;
}
.p-tooltip {
  &.bg-dark {
    .p-tooltip-text {
      background: #000;
    }
  }
}

.ah-multiselect-dropdown-panel {
  .p-checkbox {
    .p-checkbox-box {
      width: 13px;
      height: 13px;
      border-radius: 2px;
    }
    .p-checkbox-icon {
      width: 10px;
      height: 10px;
    }
  }
  .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #495057;
    background: #e9ecef;
  }
  .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
    background: rgb(241, 242, 244);
  }
}

.margin {
  &.ml-sm-12 {
    margin-left: 12px;
  }
}

.ah-avatar-group {
  .p-avatar + .p-avatar {
    margin-left: -0.5rem;
  }
}

.ah-modal-container {
  background-color: var(--bg-white);
  width: 100%;
  margin: 0 auto;
  position: relative;
  border-radius: var(--border-radius-md);
  text-align: left;
  max-height: 524px;
  overflow: auto;
  padding: 15px;

  @media (min-width: 768px) {
    width: 49.5%;
    padding: 25px;
  }
}

.iconposition-to-text {
  position: relative;
  top: 2px;
}
